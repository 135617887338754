._2oSbenaQCQZPXOD75Sj6nE {
  border: 1px solid #414141 !important;
}

._23VzqtDiBsdmyddgDcP2FF,
._2JJI18xT4B0iIKyuZLMpk9 {
  fill: #414141 !important;
}

.fe6xZVPr1kb0CJksW39zV {
  font-size: 12px !important;
  color: #414141 !important;
}

.MuiIconButton-colorSecondary {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  color: #414141 !important;
}



.Mui-checked {
  color: #414141 !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2 ) !important;
}

.MuiFormControlLabel-label {
  font-size: 12px !important;
  width: 100% !important;
}

.MuiDialog-paperWidthMd {
  max-width: 980px;
}

.hotspot {
  margin: 0;
}
.hotspot .button-wrap {
  display: table;
}
.hotspot .pulse-button {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  display: table-cell;
  width: 40px;
  height: 40px;
  font-size: 1.3em;
  font-weight: bold;
  color: #ffffff;
  border: none;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  vertical-align: auto;
  background: #efc743;
  cursor: pointer;
  -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 2px #000;
  -webkit-text-shadow: 0 0 2px #000;
}

.none{
  display: none !important;
}

#circle {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: red;
}

.rcp-fields {
  display: none !important;
}

.unity-canvas{
  width: 1280px !important;
  height: 700px !important
}




.hotspot .pulse-button-wall {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  display: table-cell;
  width: 40px;
  height: 40px;
  font-size: 1.3em;
  font-weight: bold;
  color: #ffffff;
  border: none;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  vertical-align: middle;
  background: #ffffffcc;
  cursor: pointer;
  -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 0 0 2px #000;
  -webkit-text-shadow: 0 0 2px #000;
}
.hotspot .pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
.hotspot .pulse-button.deactivate {
  color: #000;
  background: #999;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

.cls-1 {
  fill: #fff;
  stroke: #f4f2f3;
  stroke-miterlimit: 10;
}

.pivoted{
  padding-left:  0  !important;
}

[data-rcs="handle-container"] {
  z-index: 9;
}

*:focus {
  outline: none;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 6px !important;
}

@media screen and (max-width: 1024px){
  .unity-canvas{
    width: 970px !important;
    height: 620px !important
  }
}

@media screen and (max-width: 800px){

  .hotspot .pulse-button {
    width: 25px;
    height: 25px;
    font-size: 0.8em;
  }

  .unity-canvas{
    width: 750px !important;
    height: 320px !important
  }

  .hotspot .pulse-button-wall {
    width: 25px;
    height: 25px;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 950px) and (orientation: landscape){

  .hotspot .pulse-button {
    width: 25px;
    height: 25px;
    font-size: 0.8em;
  }

  .unity-canvas{
    width: 750px !important;
    height: 320px !important
  }

  .hotspot .pulse-button-wall {
    width: 25px;
    height: 25px;
    font-size: 0.8em;
  }
}
@media screen and (max-width: 390px){

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 2px !important;
}

}

@media (max-width: 1250px) {
.tabContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.lds-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  position: absolute;
  top: 50%;
  left:50%
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(239, 199, 67);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.video-react-icon-fullscreen {
  visibility: hidden;
  }