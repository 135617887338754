.carousel .control-dots {
    bottom: 35px;
}

@media (orientation: portrait) {
  .carousel .control-dots {
    bottom: 0px;
  }
}

ul.thumbs.animated {
  position: absolute;
  top: -200px;
}
.carousel .slide{
  background: #fff;
  text-align: left;
}